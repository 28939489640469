import React from 'react'
import Navigation from './Navigation'
import styled, {createGlobalStyle} from 'styled-components'

const GlobalStyle= createGlobalStyle`
    body{
        margin: 0px;
        padding: 0px;
        -ms-overflow-style:none;
        ::-webkit-scrollbar{
            display:none;
        }
        background-color: #F8F8F8;
    }
    html{
        margin: 0px;
        padding: 0px;
        scrollbar-width: none;
    }
`
const MainContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-right:5%;
    margin-left:5%;
    height: calc(100vh - 4vh);
`
export default ({children}) => (
    <React.Fragment>
        <GlobalStyle/>
        <Navigation/>
        <MainContent>
            {children}
        </MainContent>
    </React.Fragment>
)
